/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  $.fn.extend({
    toggleClass: function(c) {
        if($(this).hasClass(c)) {
            $(this).removeClass(c);
        }

        else {
            $(this).addClass(c);
        }
    },
  });
})(jQuery);



(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $.viewportWidth = function() {
            return window.innerWidth;
        };

        window.breakpoints = (function() {
            var breakpoints = JSON.parse($(".js-breakpoints-data").text());

            Object.keys(breakpoints).forEach(function(key) {
                // these are defined in ems, so multiply to get
                // the pixel value
                breakpoints[key] = parseInt(breakpoints[key].min_width) * 16;
            });

            return breakpoints;

        })();

        console.log("Registered the following breakpoints:");

        Object.keys(window.breakpoints).forEach(function(key) {
            console.log("\t" + key + ": " + window.breakpoints[key]);
        });

        $(".js-matchheight").matchHeight();
        $(".js-dropdown-height").matchHeight();
        $(".card").not(".card--featured").matchHeight();


        $(".menu-button, .drawer-nav__close-button, .drawer-nav__background").click(function() {
            $(".drawer-nav").toggleClass("drawer-nav--shown");
        });

        $(".drawer-nav__item").each(function(){
            if($(this).find(".drawer-nav__item__drawer-nav").length > 0) {
                $(this).hover(
                    function(){
                        if($(window).width() >= window.breakpoints.md) {
                            $(this).find(".drawer-nav__item__drawer-nav").addClass("drawer-nav__item__drawer-nav--shown");
                        }
                    },
                    function(){
                        if($(window).width() >= window.breakpoints.md) {
                            $(this).find(".drawer-nav__item__drawer-nav").removeClass("drawer-nav__item__drawer-nav--shown");
                        }
                    }
                );
            }
        });

        (function() {
            var $body = $("body");
            var $header = $(".header");
            var adjustBodyPadding = function() {
                $body.css({
                    paddingTop: $header.height(),
                });
            };
            adjustBodyPadding();
            $(window).resize(adjustBodyPadding);
        })();

        (function() {
            var $matchAlways = $(".js-matchheight-always");
            var matchHeight  = function() {
                var maxHeight = 0;

                $matchAlways.each(function() {
                    maxHeight = maxHeight > $(this).outerHeight() ? maxHeight : $(this).outerHeight();

                    console.log($(this).outerHeight());
                });

                $matchAlways.each(function() {
                    $(this).height(maxHeight);
                });
            };

            $(window).resize(matchHeight);

            $(window).load(matchHeight);
        })();

        (function() {
            var slider = function() {
                var firstClass = 'reviews-slider__review--first';
                var lastClass  = 'reviews-slider__review--last';

                var cleanupClasses = function() {
                    $(".reviews-slider__inner .reviews-slider__review").each(function() {
                        $(this).removeClass(firstClass).removeClass(lastClass);
                    });
                };

                if($(".reviews-slider__inner.slick-initialized").length > 0) {
                    $(".reviews-slider__inner").slick('unslick').css({ width: '' });
                    cleanupClasses();
                }

                if($(window).width() >= window.breakpoints.md) {
                    var slideWidth = $(".js-review-slider-reference").width() * 0.75;
                    var slidesToShow = Math.ceil($(window).width() / slideWidth);
                    var sliderWidth = slidesToShow * slideWidth;
                    var assignFirstLast = function(n) {
                        cleanupClasses();

                        $(".reviews-slider__inner .slick-slide[data-slick-index=" +
                            (n - 1) +
                        "] .reviews-slider__review").addClass(firstClass);
                        $(".reviews-slider__inner .slick-slide[data-slick-index=" +
                            (n + 1) +
                        "] .reviews-slider__review").addClass(lastClass);
                    };

                    $(".reviews-slider__inner").css({
                        width: sliderWidth,
                    }).slick({
                        lazyLoad: 'progressive',
                        slidesToShow: slidesToShow,
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 8000,
                    });

                    $(".reviews-slider__inner .slick-track").css({ left: (sliderWidth * 0.065) + 'px' });

                    $(".reviews-slider__inner").on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                        assignFirstLast(nextSlide);
                    });

                    assignFirstLast(0);
                }
                else {
                    $(".reviews-slider__inner").slick();
                }
            };
            $(window).resize(slider); slider();
            var arrowsFor = function(className) {

                return function() {

                    var direction = $(this).data('direction');
                    var $slider   = $(className);

                    var opts = {
                        'right': 'slickNext',
                        'left': 'slickPrev',
                    }, res = opts[direction];

                    if(!res) {
                        throw "Invalid slider option.";
                    }

                    $(this).click(function() {
                        $slider.slick(res);
                    });
                };
            };

            $(".js-home-arrow").each(arrowsFor(".services-slider__inner"));
            $(".js-reviews-arrow").each(arrowsFor(".reviews-slider__inner"));
        })();

        (function() {
            var $drawer = $(".drawer-nav");
            $(".drawer-nav__item").each(function() {
                // console.log(this);
                var $item = $(this);
                $item.find(".drawer-nav__item__more-arrow, .drawer-nav__item__less-arrow").on('click', function() {
                  console.log('click');
                    var subMenuOpen = false;
                    $item
                        .find(".drawer-nav__item__drawer-nav")
                        .toggleClass("drawer-nav__item__drawer-nav--shown");
                    $drawer
                        .find(".drawer-nav__item__drawer-nav")
                        .each(function() {
                        if($(this).hasClass("drawer-nav__item__drawer-nav--shown")) {
                            subMenuOpen = true;
                        }
                    });
                    if(subMenuOpen) {
                        if(!$drawer.hasClass("drawer-nav--child-menu-open")) {
                            $drawer.addClass("drawer-nav--child-menu-open");
                        }
                    }
                    else {
                        $drawer.removeClass("drawer-nav--child-menu-open");
                    }
                });
            });
        })();

        $("label").each(function() {
            var content = $(this).text();

            if(/^\s+$/.test(content)) {
                $(this).remove();
            }
        });

        $(".js-fade-in-child").each(function() {
            var $child = $(this).find(".js-fade-in-this");

            $(this).hover(function() {
                $child.css({
                    display: 'block',
                });
            }, function() {
                $child.css({
                    display: 'none',
                });
            });
        });

        $(".service-sibling-nav").each(function() {
            var $this = $(this);
            var $lastChild = $this.find(".service-sibling-nav__item:last-of-type");
            var maybeOverflow = function() {
                if(($lastChild.position().left + $lastChild.width()) > $this.width()) {
                    $this.addClass('service-sibling-nav--overflowed');
                }
                else {
                    $this.removeClass('service-sibling-nav--overflowed');
                }
            };
            $(window).resize(maybeOverflow);
            maybeOverflow();
        });

        $(".service-card").each(function(){
            var height = $(this).height();
            if(!this.style.height) {
                $(this).hover(
                    function(){
                        $(this).height(height);
                    },
                    function(){
                        setTimeout(function () {
                            $(this).css('height', '20px');
                        }, 2000);
                    }
                );
            }
        });

        $(".service-category-select").each(function(){
            $("option").each(function(){
                if($(this).val() === window.location.href ){
                    $(this).attr('selected', 'selected');
                }
            });
            $(this).on('change', function(){
                window.location.assign($(this).val());
            });
        });

        (function() {
                var lastScrollDownPos = 0;
                var lastScrollPos = 0;
                var scrollUpThreshold = 80;
                var scrollDownThreshold = $(".js-header").height();

                var menusHidden = false;

                window.hideMenus = function() {
                    $(".js-header").addClass('hide-menu');
                };

                window.showMenus = function() {
                    $(".js-header").removeClass("hide-menu");
                };

                $(window).scroll(function() {
                    var thisScrollPos = $(document).scrollTop();
                    if($(window).width() <= window.breakpoints.md) {

                        if($(window).height() + $(window).scrollTop() > $(document).height() - 100) {
                            showMenus();
                        }

                        else if(thisScrollPos > lastScrollPos && thisScrollPos > scrollDownThreshold) {
                            lastScrollDownPos = thisScrollPos;
                            hideMenus();
                        }

                        else if(lastScrollDownPos - thisScrollPos > scrollUpThreshold) {
                            showMenus();
                        }
                    }

                    lastScrollPos = thisScrollPos;

                });

                $(window).resize(function(){
                    if($(window).width() >= window.breakpoints.md) {
                        showMenus();
                    }
                });
        })();

        $('.text-number').each(function() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            var href = $(this).attr('href');

            if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream ) {
                href = "sms:+12108997696?&body=Hey%2C%20Champion%20AC%21%20I%27m%20interested%20in%20getting%20more%20information%20about%20your%20services%2E";
                this.href = href;
            }
        });


/*
        (function() {
            var $serviceAreas = $(".js-service-areas");
            var setupTeardown = function() {
                if($(window).width() < window.breakpoints.xs || $(window).width() >= window.breakpoints.lg) {
                    $serviceAreas.masonry('destroy');
                }
                else {
                    $serviceAreas.masonry({
                        itemSelector: ".service-area-list",
                    });
                }
            };
            setupTeardown();
            $(window).resize(setupTeardown);
        })();
*/
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'page_template_template_home': {
      init: function() {
          var $textSlider = $(".home-text-slider__inner").slick({
              arrows: false,
              autoplay: true,
              autoplaySpeed: 8000,
          });
          $(".home-text-slider__arrow--left").click(function() {
              $textSlider.slick('slickPrev');
          });
          $(".home-text-slider__arrow--right").click(function() {
              $textSlider.slick('slickNext');
          });
          $(".badge-slider").slick({
              slidesToShow: 7,
              arrows: false,
              autoplay: true,
              autoplaySpeed: 8000,
              responsive: [
                  {
                      breakpoint: window.breakpoints.xs - 1,
                      settings: {
                          slidesToShow: 3,
                      },
                  },
                  {
                      breakpoint: window.breakpoints.md - 1,
                      settings: {
                          slidesToShow: 4,
                      },
                  },
                  {
                      breakpoint: window.breakpoints.lg - 1,
                      settings: {
                          slidesToShow: 5,
                      },
                  },
              ],
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_about_us': {
      init: function() {
        $(".js-team-member").each(function() {
            var _ = this;
            $(_).magnificPopup({
                preloader: true,
                items: {
                    type: 'inline',
                    src: $('<div class="bg-white my4">' +
                        '<div class="js-popup-content">' +
                            '<div class="center fs-28 p4">' +
                                '<i class="fas fa-spin fa-spinner"></i>' +
                            '</div>' +
                        '</div>' +
                    '</div>'),
                },
                callbacks: {
                    open: function() {
                        $.ajax({
                            url: ajaxUrl,
                            method: 'GET',
                            data: {
                                action: 'team_member_modal_html',
                                post: $(_).data('id'),
                            },
                            success: function(res) {
                                var data = res.data;
                                if('https:' === window.location.protocol) {
                                    data = data.replace(/http:/g, 'https:');
                                }
                                $(".js-popup-content").children().remove();
                                $(".js-popup-content").append(data);
                            },
                        });
                    },
                },
            });
        });
      }
    },
    'page_template_template_team': {
      init: function() {
        $(".js-apply-now").each(function() {
            var _ = this;
            $(_).magnificPopup({
              items: {
                src: '#apply-now',
                type: 'inline',
                midClick: true
              }
            });
        });
      }
    },
    'index': {
        init: function() {

            $(".card").not(".card--featured").matchHeight();
            $(".card--featured .card__top, .card--featured .card__bottom").matchHeight();

        },
    },
    'post_type_archive_coupon': {
        init: function() {
            $(".coupon__left, .coupon__right").matchHeight();
            $(".js-coupon").each(function() {
                var _ = this;
                var note = $(_).attr("data-note");
                $(_).magnificPopup({
                    items: {
                        type: 'inline',
                        src: $('<div class="bg-white">' +
                            '<div class="js-popup-content">' +
                                '<div class="center fs-18 p4 sans">' +
                                    note +
                                '</div>' +
                            '</div>' +
                        '</div>'),
                    },
                });
            });
        },
    },
    'post_type_archive_champion_101': {
        init: function() {
            $(".card--champion-101").matchHeight();
        },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
